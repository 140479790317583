exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-authors-js": () => import("./../../../src/pages/authors.js" /* webpackChunkName: "component---src-pages-authors-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-ebook-education-index-js": () => import("./../../../src/pages/ebook/education/index.js" /* webpackChunkName: "component---src-pages-ebook-education-index-js" */),
  "component---src-pages-ebook-finance-index-js": () => import("./../../../src/pages/ebook/finance/index.js" /* webpackChunkName: "component---src-pages-ebook-finance-index-js" */),
  "component---src-pages-ebook-government-index-js": () => import("./../../../src/pages/ebook/government/index.js" /* webpackChunkName: "component---src-pages-ebook-government-index-js" */),
  "component---src-pages-ebook-health-index-js": () => import("./../../../src/pages/ebook/health/index.js" /* webpackChunkName: "component---src-pages-ebook-health-index-js" */),
  "component---src-pages-ebook-retail-index-js": () => import("./../../../src/pages/ebook/retail/index.js" /* webpackChunkName: "component---src-pages-ebook-retail-index-js" */),
  "component---src-pages-ebook-technology-index-js": () => import("./../../../src/pages/ebook/technology/index.js" /* webpackChunkName: "component---src-pages-ebook-technology-index-js" */),
  "component---src-pages-ebooks-js": () => import("./../../../src/pages/ebooks.js" /* webpackChunkName: "component---src-pages-ebooks-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-authors-js": () => import("./../../../src/pages/news/authors.js" /* webpackChunkName: "component---src-pages-news-authors-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-why-securiwiser-js": () => import("./../../../src/pages/why-securiwiser.js" /* webpackChunkName: "component---src-pages-why-securiwiser-js" */),
  "component---src-template-author-template-js": () => import("./../../../src/template/authorTemplate.js" /* webpackChunkName: "component---src-template-author-template-js" */),
  "component---src-template-blog-template-js": () => import("./../../../src/template/blogTemplate.js" /* webpackChunkName: "component---src-template-blog-template-js" */),
  "component---src-template-category-template-js": () => import("./../../../src/template/categoryTemplate.js" /* webpackChunkName: "component---src-template-category-template-js" */),
  "component---src-template-features-template-js": () => import("./../../../src/template/featuresTemplate.js" /* webpackChunkName: "component---src-template-features-template-js" */),
  "component---src-template-industry-template-js": () => import("./../../../src/template/industryTemplate.js" /* webpackChunkName: "component---src-template-industry-template-js" */)
}

