/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

const React = require("react");

// this is for unlocking mobile navbar background scroll
exports.onRouteUpdate = () => {
    const htmlElement =  document.querySelector('html');  
    htmlElement.className = "";
}
